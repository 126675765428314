@import url("https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700&display=swap");
/*@import url('https://fonts.googleapis.com/css?family=Advent+Pro:500');*/

html {
  font-size: 15px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.is-clipped {
  position: fixed;
  overflow: hidden !important;
  height: 100% !important;
}

body,
button,
input,
select,
textarea {
  /*font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;*/
  font-family: "Old Standard TT", serif;
}

body {
  color: #16120F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 100vh;
  background-color: #CAC0B6;
  background-color: #D7D2CA;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Old Standard TT", serif;
  font-weight: 700;
}

img {
  max-width: 100%;
}
